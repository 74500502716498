import { AccordionPanel, Stack } from '@chakra-ui/react';
import { useApproveLDTransaction } from 'hooks';
import { useTranslation } from 'localization';
import { Transaction } from 'types';

import {
  Button,
  DownloadCard,
  LDTransactionCard,
  LDTransactionMessage,
} from 'components';

type ProposalCardProps = {
  transaction: Transaction;
  refetch: () => void;
};

const ProposalCard: React.FC<ProposalCardProps> = ({
  transaction,
  refetch,
}: ProposalCardProps) => {
  const { t } = useTranslation('component.requests.transactions');

  const { mutateAsync: approve, isLoading: approveLoading } =
    useApproveLDTransaction();

  const STATES: Record<Transaction['status'], React.ReactElement | string> = {
    INIT: '',
    AUTHORIZED: '',
    APPROVED: '',
    UNAUTHORIZED: (
      <Stack>
        <LDTransactionMessage
          title={t('transactionNotCleared')}
          status="error"
          textProps={{ color: 'error' }}
        />
      </Stack>
    ),
    CLEARED: transaction?.form?.deposit_ld?.proposal ? (
      transaction?.form?.deposit_ld?.proposal?.payment_method === 'CASH' ? (
        <Stack flexDir="column">
          <LDTransactionMessage
            title={t('addressHint')}
            status="error"
            textProps={{ color: 'error' }}
          />
          <Button
            title={t('approve')}
            onClick={() =>
              approve({ id: transaction.id }).then(() => refetch())
            }
            isLoading={approveLoading}
          />
        </Stack>
      ) : transaction?.form?.deposit_ld?.bank_receipt ? (
        <Stack flexDir="column">
          <LDTransactionMessage
            title={t('userPayedWithBankTransfer')}
            status="info"
            textProps={{ color: 'info' }}
          />
          <DownloadCard file={transaction?.form?.deposit_ld?.bank_receipt} />
          <Button
            title={t('approve')}
            onClick={() =>
              approve({ id: transaction.id }).then(() => refetch())
            }
            isLoading={approveLoading}
          />
        </Stack>
      ) : (
        <Stack flexDir="column">
          <LDTransactionMessage
            title={t('waitForUserPaying')}
            status="info"
            textProps={{ color: 'info' }}
          />
        </Stack>
      )
    ) : (
      <Stack flexDir="column">
        <LDTransactionMessage
          title="Cleared"
          status="info"
          textProps={{ color: 'info' }}
        />
      </Stack>
    ),
    NOT_CLEARED: (
      <Stack>
        <LDTransactionMessage
          title={t('transactionNotCleared')}
          status="error"
          textProps={{ color: 'error' }}
        />
      </Stack>
    ),
    CANCELED: (
      <Stack>
        <LDTransactionMessage
          title={t('transactionNotCleared')}
          status="error"
          textProps={{ color: 'error' }}
        />
      </Stack>
    ),
    SETTLED: (
      <Stack>
        {transaction?.form?.deposit_ld?.proposal
          ? transaction?.form?.deposit_ld?.proposal?.payment_method ===
              'BANK_TRANSFER' && (
              <DownloadCard
                file={transaction?.form?.deposit_ld?.bank_receipt || ''}
              />
            )
          : ''}
        <LDTransactionMessage
          title={t('transactionDone')}
          status="success"
          textProps={{ color: 'success' }}
        />
      </Stack>
    ),
    DENIED: (
      <Stack>
        <LDTransactionMessage
          title={t('transactionNotCleared')}
          status="error"
          textProps={{ color: 'error' }}
        />
      </Stack>
    ),
    FAILED: '',
    SETTLING: '',
  };

  return (
    <AccordionPanel pb={4}>
      <LDTransactionCard
        amount={transaction.amount}
        identifier_number={
          transaction?.destination_wallet?.user?.identifier_number || 0
        }
        pay_with={transaction?.form?.deposit_ld?.proposal?.pay_with}
        symbol={transaction?.source_wallet?.currency?.symbol}
        rate={transaction?.form?.deposit_ld?.proposal?.rate}
        note={transaction?.form?.deposit_ld?.proposal?.note}
        payment_method={transaction?.form?.deposit_ld?.proposal?.payment_method}
        receivingValue={
          transaction.amount * transaction?.form?.deposit_ld?.proposal?.rate
        }
        address={transaction?.form?.deposit_ld?.proposal?.address}
        panel="LD"
        method="DEPOSIT"
      />
      <Stack mt="2">{STATES[transaction.status]}</Stack>
    </AccordionPanel>
  );
};
export default ProposalCard;
