import React from 'react';
import { Box, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import { ReactComponent as Pending } from 'assets/icons/pendingIcon.svg';
import { ReactComponent as Success } from 'assets/icons/successIcon.svg';
import { useCryptoWhiteList } from 'hooks';
import { useTranslation } from 'localization';

import { CopyClipBoard, Spinner } from 'components';

const Whitelist: React.FC = () => {
  const { t } = useTranslation('component.settings.security');
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  const { data: whitelist, isLoading } = useCryptoWhiteList({ queries: {} });

  return (
    <Box w="100%" bgColor="dark" my={8} gap={4}>
      <Text fontSize={{ base: 'md', lg: '2xl' }} fontWeight="bold" p={4}>
        {t('whitelist')}
      </Text>
      {isLoading ? (
        <Stack w="100%" align="center" justify="center">
          <Spinner />
        </Stack>
      ) : (
        <Box w="100%">
          {whitelist?.list.length === 0 && (
            <Text w="100%" textAlign="center" py={4}>
              {t('noWhitelist')}
            </Text>
          )}
          {whitelist?.list.map((item) => {
            return (
              <Box
                key={item.id}
                w="100%"
                display="flex"
                flexDir={{ base: 'column', xl: 'row' }}
                alignItems="center"
                justifyContent="space-between"
                my={2}
                p={4}
                bgColor="lightPrimary"
                gap={4}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={4}
                >
                  <Box>
                    {item.status === 'NOT_VERIFIED' && <Pending />}
                    {item.status === 'VERIFIED' && <Success width={24} />}
                  </Box>
                  <Text>{item.title || '-'}</Text>
                </Box>
                <CopyClipBoard
                  initialValue={item.wallet_address}
                  showCharacterLength={isLargerThan800 ? 32 : 16}
                />
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default Whitelist;
