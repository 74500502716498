import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Stack } from '@chakra-ui/react';
import { ReactComponent as MagixGroupLogo } from 'assets/icons/magixGroupLogo.svg';
import { routes } from 'statics';

import Invalid from './components/Invalid';
import PaymentProcess from './components/PaymentProcess';

const Payment: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resultType = searchParams.get('type');
  const transactionID = searchParams.get('transaction_id');

  React.useEffect(() => {
    if (!transactionID && !resultType) {
      navigate(routes.dashboard.url);
    }
  }, [transactionID, resultType]);

  return (
    <Box
      bg="secondary"
      width="full"
      height="100%"
      minH="100vh"
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="flex-start"
      p={4}
      gap={6}
    >
      <Box py={4}>
        <MagixGroupLogo />
      </Box>
      <Stack align="center" width="full">
        {resultType === 'invalid' && <Invalid />}
        {transactionID && <PaymentProcess transactionID={transactionID} />}
      </Stack>
    </Box>
  );
};

export default Payment;
