const AUTH = '/auth';
const DASHBOARD = '/dashboard';
const WALLETS = '/wallets';
const EXCHANGE = '/exchange';
const TRANSFER = '/transfer';
const SETTINGS = '/settings';
const SUPPORT = '/support';
const REQUESTS = '/requests';
const SERVICES = '/services';
const XM = `${SERVICES}/xm`;
const PAYMENT = '/payment';

const ROUTES = {
  root: { url: '/', href: (): string => '/' },
  settings: { url: SETTINGS, href: (): string => SETTINGS },
  auth: {
    url: AUTH,
    signUp: { url: `${AUTH}/sign-up` },
    password: { url: `${AUTH}/password` },
    signIn: { url: `${AUTH}/login` },
    verification: { url: `${AUTH}/verification` },
    forgotPassword: { url: `${AUTH}/forgot-password` },
    newPassword: { url: `${AUTH}/new-password` },
  },
  dashboard: { url: DASHBOARD },
  wallets: { url: WALLETS },
  exchange: { url: EXCHANGE },
  transfer: { url: TRANSFER },
  requests: { url: REQUESTS },
  support: { url: SUPPORT },
  deposit: { url: `${WALLETS}/deposit` },
  withdraw: { url: `${WALLETS}/withdraw` },
  services: {
    url: SERVICES,
    xm: {
      deposit: { url: `${XM}/deposit` },
      withdraw: { url: `${XM}/withdraw` },
    },
  },
  payment: { url: PAYMENT },
};

export default ROUTES;
