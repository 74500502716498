const fixDecimal = ({
  value = 0,
  fractionDigits = 2,
}: {
  value: number;
  fractionDigits?: 1 | 2 | 3 | 4 | 5;
}): string => {
  if (typeof value === 'number') {
    const stringOfValue = value.toString();
    if (stringOfValue.indexOf('.') >= 0) {
      if (stringOfValue.includes('e')) {
        return fixDecimal({
          value: parseInt(value.toFixed(20).replace(/\.?0+$/, '')),
          fractionDigits,
        });
      }

      const [fix, decimals] = stringOfValue.split('.');
      return fix + '.' + (decimals + '00000').slice(0, fractionDigits);
    }
    return `${stringOfValue}.${'00000'.slice(0, fractionDigits)}`;
  }
  return '0.00';
};

export default fixDecimal;
