import { Profile } from 'types';

const access = ({ profile }: { profile: Profile | undefined }) => {
  const hasService = (service: string): boolean => {
    return profile
      ? profile.role.permissionsStringified
          .map((item) => item.split(':')[0])
          .includes(`${service}`)
      : false;
  };
  const hasPermission = (permission: string): boolean => {
    return profile
      ? profile.role.permissionsStringified.includes(permission)
      : false;
  };

  return {
    sessionsService: hasService('SESSION'),
    sessionListPermission: hasPermission('SESSION:LIST'),
    sessionReadPermission: hasPermission('SESSION:READ'),
    sessionDeletePermission: hasPermission('SESSION:READ'),
    contactService: hasService('CONTACT'),
    contactListPermission: hasPermission('CONTACT:LIST'),
    contactReadPermission: hasPermission('CONTACT:READ'),
    contactCreatePermission: hasPermission('CONTACT:CREATE'),
    contactDeletePermission: hasPermission('CONTACT:DELETE'),
    contactSendVerificationOtpPermission: hasPermission(
      'CONTACT:SEND_VERIFICATION_OTP'
    ),
    contactVerifyPermission: hasPermission('CONTACT:VERIFY'),
    contactPrimaryPermission: hasPermission('CONTACT:PRIMARY'),
    meReadPermission: hasPermission('ME:READ'),
    meDeletePermission: hasPermission('ME:DELETE'),
    meBalanceReadPermission: hasPermission('ME_BALANCE:READ'),
    kycLevel1Permission: hasPermission('KYC_LEVEL_1:CREATE'),
    kycLevel2Permission: hasPermission('KYC_LEVEL_2:CREATE'),
    authProfileService: hasService('AUTH_PROFILE'),
    authProfileReadPermission: hasPermission('AUTH_PROFILE:READ'),
    authProfileUpdatePermission: hasPermission('AUTH_PROFILE:UPDATE'),
    authService: hasService('AUTH'),
    authUpdatePasswordPermission: hasPermission('AUTH:UPDATE_PASSWORD'),
    authLogoutPermission: hasPermission('AUTH:LOGOUT'),
    walletService: hasService('WALLET'),
    walletListPermission: hasPermission('WALLET:LIST'),
    walletReadPermission: hasPermission('WALLET:READ'),
    walletCreatePermission: hasPermission('WALLET:CREATE'),
    walletDeletePermission: hasPermission('WALLET:DELETE'),
    bankAccountService: hasService('BANK_ACCOUNT'),
    bankAccountListPermission: hasPermission('BANK_ACCOUNT:LIST'),
    bankAccountReadPermission: hasPermission('BANK_ACCOUNT:READ'),
    bankAccountCreatePermission: hasPermission('BANK_ACCOUNT:CREATE'),
    transactionService: hasService('TRANSACTION'),
    transactionListPermission: hasPermission('TRANSACTION:LIST'),
    transactionReadPermission: hasPermission('TRANSACTION:READ'),
    transactionMethodService: hasService('TRANSACTION_METHOD'),
    transactionMethodListPermission: hasPermission('TRANSACTION_METHOD:LIST'),
    transactionMethodReadPermission: hasPermission('TRANSACTION_METHOD:READ'),
    currencyService: hasService('CURRENCY'),
    currencyListPermission: hasPermission('CURRENCY:LIST'),
    currencyReadPermission: hasPermission('CURRENCY:READ'),
    depositBankTransferCreatePermission: hasPermission(
      'DEPOSIT_BANK_TRANSFER:CREATE'
    ),
    requestLDService:
      hasPermission('DEPOSIT_LD_REQUESTS:LIST') &&
      hasPermission('WITHDRAW_LD_REQUESTS:LIST'),
    depositLDRequestListPermission: hasPermission('DEPOSIT_LD_REQUESTS:LIST'),
    depositLDUsersListPermission: hasPermission('DEPOSIT_LD_USERS:LIST'),
    depositLDService: hasService('DEPOSIT_LD'),
    depositLDCreatePermission: hasPermission('DEPOSIT_LD:CREATE'),
    depositLDApprovePermission: hasPermission('DEPOSIT_LD:APPROVE'),
    swapService: hasService('SWAP'),
    swapCreatePermission: hasPermission('SWAP:CREATE'),
    swapApprovePermission: hasPermission('SWAP:APPROVE'),
    rateProvidePermission: hasPermission('RATE:PROVIDE'),
    withdrawSendOtpPermission: hasPermission('WITHDRAW:SEND_OTP'),
    withdrawBankService: hasService('WITHDRAW_BANK_TRANSFER'),
    withdrawBankCreatePermission: hasPermission(
      'WITHDRAW_BANK_TRANSFER:CREATE'
    ),
    withdrawBankApprovePermission: hasPermission(
      'WITHDRAW_BANK_TRANSFER:APPROVE'
    ),

    withdrawLDRequestsListPermission: hasPermission(
      'WITHDRAW_LD_REQUESTS:LIST'
    ),
    withdrawLDUsersListPermission: hasPermission('WITHDRAW_LD_USERS:LIST'),
    withdrawLDService: hasService('WITHDRAW_LD'),
    withdrawLDCreatePermission: hasPermission('WITHDRAW_LD:CREATE'),
    withdrawLDApprovePermission: hasPermission('WITHDRAW_LD:APPROVE'),
    withdrawLDSettlePermission: hasPermission('WITHDRAW_LD:SETTLE'),
    withdrawCryptoCreatePermission: hasPermission('WITHDRAW_CRYPTO:CREATE'),
    transferService: hasService('TRANSFER'),
    transferCreatePermission: hasPermission('TRANSFER:CREATE'),
    transferApprovePermission: hasPermission('TRANSFER:APPROVE'),
    whiteListCryptoWithdrawalService: hasService(
      'WHITELIST_CRYPTO_WITHDRAWAL_ACCOUNT'
    ),
    whiteListCryptoWithdrawalListPermission: hasPermission(
      'WHITELIST_CRYPTO_WITHDRAWAL_ACCOUNT:LIST'
    ),
    whiteListCryptoWithdrawalCreatePermission: hasPermission(
      'WHITELIST_CRYPTO_WITHDRAWAL_ACCOUNT:CREATE'
    ),
    whiteListCryptoWithdrawalReadPermission: hasPermission(
      'WHITELIST_CRYPTO_WITHDRAWAL_ACCOUNT:READ'
    ),
    whitelistSendOtpPermission: hasPermission('WHITELIST:SEND_OTP'),
    withdrawService:
      hasService('WITHDRAW_BANK_TRANSFER') ||
      hasPermission('WITHDRAW_CRYPTO:CREATE') ||
      hasPermission('WITHDRAW_LD_REQUESTS:LIST'),
    verifyInitiateExternalServicePermission: hasPermission(
      'EXTERNAL_USER:VERIFY'
    ),
    rejectInitiateExternalServicePermission: hasPermission(
      'EXTERNAL_USER:REJECT'
    ),
    verifyWithdrawExternalServicePermission: hasPermission(
      'WITHDRAW_BROKER:APPROVE'
    ),
    rejectWithdrawExternalServicePermission: hasPermission(
      'WITHDRAW_BROKER:REJECT'
    ),
    ldProposalList: hasPermission('LD_PROPOSAL:LIST'),
    ldProposalRead: hasPermission('LD_PROPOSAL:READ'),
    ldProposalCreate: hasPermission('LD_PROPOSAL:CREATE'),
    ldProposalApprove: hasPermission('LD_PROPOSAL:APPROVE'),
    ldProposalCancel: hasPermission('LD_PROPOSAL:CANCEL'),
    ldProposalBankAccount: hasPermission('LD_PROPOSAL:BANK_ACCOUNT'),
    ldProposalReject: hasPermission('LD_PROPOSAL:REJECT'),
    ldProposalSettle: hasPermission('LD_PROPOSAL:SETTLE'),
    ldProposalRate: hasPermission('LD_PROPOSAL:RATE'),
    ldProposalCashPayment: hasPermission('LD_PROPOSAL:CASH_PAYMENT'),
    depositPaymentCreatePermission: hasPermission('DEPOSIT_PAYMENT:CREATE'),
  };
};

export default access;
