import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, PaymentGateways } from 'types';

const usePaymentGateways = ({
  queries,
  enabled,
}: {
  queries: { pay_currency: string };
  enabled: boolean;
}): UseQueryResult<PaymentGateways[], ErrorResponse> => {
  let queryParams = '?';
  if (Object.entries(queries).length > 0) {
    Object.entries(queries).map(([key, value], i) => {
      queryParams += `${key}=${value}${
        i === Object.entries(queries).length - 1 ? '' : '&'
      }`;
    });
  }

  return useQuery([`${url.paymentGateways}${queryParams}`], { enabled });
};
export default usePaymentGateways;
