import React from 'react';
import { Box, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { ReactComponent as Pending } from 'assets/icons/pendingIcon.svg';
import { ReactComponent as Success } from 'assets/icons/successIcon.svg';
import { useBankAccounts } from 'hooks';
import { useTranslation } from 'localization';

const BankAccounts: React.FC = () => {
  const { data: bankAccounts } = useBankAccounts({ queries: {} });
  const { t } = useTranslation('component.settings.finance');

  return (
    <Box
      borderRadius={10}
      border="1px solid grey"
      p={4}
      w={{ base: '100%', xl: '50%' }}
    >
      <Text fontSize="xl" pb={4}>
        {t('savedBankAccounts')}
      </Text>
      <Box
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        {bankAccounts?.list.map((account, index) => {
          return (
            <React.Fragment key={account.id}>
              <VStack w="100%" p={4}>
                <HStack w="100%" align="center" justify="space-between">
                  <Text>{account.title}</Text>
                  {account.status === 'VERIFIED' ? (
                    <Box display="flex" alignItems="center" gap={2}>
                      <Text color="primary" fontSize="sm">
                        {t('verified')}
                      </Text>
                      <Success width={24} />
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center" gap={2}>
                      <Text color="warning" fontSize="sm">
                        {t('pending')}
                      </Text>
                      <Pending />
                    </Box>
                  )}
                </HStack>
                {account.bank_name && (
                  <Box
                    w="100%"
                    display="flex"
                    flexDir={{ base: 'column', md: 'row' }}
                    alignItems={{ base: 'flex-start', md: 'center' }}
                    justifyContent="space-between"
                  >
                    <Text color="body2">{t('bankName')}</Text>
                    <Text>{account.bank_name}</Text>
                  </Box>
                )}
                {account.bank_branch_name && (
                  <Box
                    w="100%"
                    display="flex"
                    flexDir={{ base: 'column', md: 'row' }}
                    alignItems={{ base: 'flex-start', md: 'center' }}
                    justifyContent="space-between"
                  >
                    <Text color="body2">{t('bankBranchName')}</Text>
                    <Text>{account.bank_branch_name}</Text>
                  </Box>
                )}
                {account.account_id && (
                  <Box
                    w="100%"
                    display="flex"
                    flexDir={{ base: 'column', md: 'row' }}
                    alignItems={{ base: 'flex-start', md: 'center' }}
                    justifyContent="space-between"
                  >
                    <Text color="body2">{t('accName')}</Text>
                    <Text>{account.account_id}</Text>
                  </Box>
                )}
                {account.phone && (
                  <Box
                    w="100%"
                    display="flex"
                    flexDir={{ base: 'column', md: 'row' }}
                    alignItems={{ base: 'flex-start', md: 'center' }}
                    justifyContent="space-between"
                  >
                    <Text color="body2">{t('phone')}</Text>
                    <Text>{account.phone}</Text>
                  </Box>
                )}
                {account.holder_name && (
                  <Box
                    w="100%"
                    display="flex"
                    flexDir={{ base: 'column', md: 'row' }}
                    alignItems={{ base: 'flex-start', md: 'center' }}
                    justifyContent="space-between"
                  >
                    <Text color="body2">{t('accHolderName')}</Text>
                    <Text>{account.holder_name}</Text>
                  </Box>
                )}
              </VStack>
              {bankAccounts.list.length - 1 > index && <Divider />}
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default BankAccounts;
