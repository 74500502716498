import React from 'react';
import { Box, Text, useDisclosure, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useProfile, useTransactions } from 'hooks';
import { useTranslation } from 'localization';
import { Transaction } from 'types';

import {
  Spinner,
  TransactionDetailModal,
  TransactionMethod,
  TransactionStatus,
} from 'components';
import { fixDecimal } from 'utils';

const RecentTransactions = () => {
  const { t } = useTranslation('component.dashboard.recentTransactions');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTransaction, setSelectedTransaction] =
    React.useState<Transaction | null>(null);

  const { data: profile } = useProfile();
  const { data: transactions, isLoading } = useTransactions({
    queries: { status: 'SETTLED' },
  });

  return (
    <VStack
      bg="dark"
      w="full"
      align="flex-start"
      p={{ base: '4', lg: '6' }}
      borderRadius="lg"
    >
      <Box
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          fontSize={{ base: 'md', lg: '26px' }}
          fontWeight={{ base: 'medium', lg: 'semibold' }}
          mb={{ base: '4', lg: 0 }}
        >
          {t('title')}
        </Text>
      </Box>
      {isLoading && (
        <Box
          w="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </Box>
      )}
      {transactions && transactions.list?.length === 0 && (
        <Text w="100%" textAlign="center">
          {t('noTransaction')}
        </Text>
      )}
      {transactions && transactions.list?.length > 0 && (
        <VStack w="100%">
          {transactions?.list?.map((transaction) => {
            const trType = transaction.transaction_method.type;
            const isDestination =
              typeof transaction.destination_wallet === 'object' &&
              transaction.destination_wallet !== null;
            const isCrypto = ['DEPOSIT_CRYPTO', 'WITHDRAW_CRYPTO'].includes(
              transaction.transaction_method.category
            );
            const isLDTr = ['DEPOSIT_LD', 'WITHDRAW_LD'].includes(
              transaction.transaction_method.category
            );
            const isBrokerTr = ['DEPOSIT_BROKER', 'WITHDRAW_BROKER'].includes(
              transaction.transaction_method.category
            );
            const amountTr = isDestination
              ? {
                  amount: transaction.destination_amount,
                  symbol: transaction.destination_wallet.currency.symbol,
                }
              : {
                  amount: transaction.amount,
                  symbol: transaction?.source_wallet?.currency?.symbol,
                };
            const swapDetail =
              trType === 'SWAP'
                ? {
                    source_amount: fixDecimal({
                      value: transaction.amount,
                      fractionDigits: isCrypto ? 5 : 2,
                    }),
                    source_symbol: transaction?.source_wallet?.currency?.symbol,
                    destination_amount: fixDecimal({
                      value: transaction.destination_amount,
                      fractionDigits: isCrypto ? 5 : 2,
                    }),
                    destination_symbol:
                      transaction?.destination_wallet?.currency?.symbol,
                  }
                : {};
            const transferDetail =
              trType === 'TRANSFER'
                ? isDestination
                  ? {
                      text: t('fromTransferField'),
                      user: transaction.form.transfer.source_identifier_number,
                    }
                  : {
                      text: t('toTransferField'),
                      user: transaction.form.transfer
                        .destination_identifier_number,
                    }
                : {};

            return (
              <Box
                key={transaction.id}
                w="100%"
                bg="secondary"
                borderRadius="lg"
                onClick={() => {
                  setSelectedTransaction(transaction);
                  onOpen();
                }}
              >
                <Box
                  display="flex"
                  flexDir={{ base: 'column', lg: 'row' }}
                  alignItems="flex-start"
                  // justifyContent={{ base: 'center', lg: 'space-between' }}
                  my="2"
                  p="4"
                  gap="4"
                  w="100%"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    w={{ base: '100%', lg: 'unset' }}
                  >
                    <Box
                      minH="40px"
                      maxH="40px"
                      minW="40px"
                      maxW="40px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <TransactionMethod
                        type={trType}
                        isIntermediary={
                          (profile?.role.title === 'LD' && isLDTr) ||
                          (profile?.role.title === 'BROKER' && isBrokerTr)
                        }
                      />
                    </Box>
                    <Box
                      minH="40px"
                      maxH="40px"
                      minW="40px"
                      maxW="40px"
                      alignItems="center"
                      justifyContent="center"
                      display={{ base: 'flex', lg: 'none' }}
                    >
                      <TransactionStatus status={transaction.status} />
                    </Box>
                  </Box>
                  {trType !== 'SWAP' && (
                    <Box
                      w="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexDir={{ base: 'row', lg: 'column' }}
                    >
                      <Text color="body2">{t('amountFrom')}</Text>
                      <Text fontSize="xs" color="body">
                        {`${
                          fixDecimal({
                            value: amountTr.amount,
                            fractionDigits: isCrypto ? 5 : 2,
                          }) || '-'
                        } ${amountTr.symbol}`}
                      </Text>
                    </Box>
                  )}
                  {trType === 'SWAP' && (
                    <Box
                      w="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexDir={{ base: 'row', lg: 'column' }}
                    >
                      <Text color="body2">{t('amountFrom')}</Text>
                      <Text fontSize="xs" color="body">
                        {`${swapDetail.source_amount || '-'} ${
                          swapDetail.source_symbol
                        }`}
                      </Text>
                    </Box>
                  )}
                  {trType === 'SWAP' && (
                    <Box
                      w="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexDir={{ base: 'row', lg: 'column' }}
                    >
                      <Text color="body2">{t('amountTo')}</Text>
                      <Text fontSize="xs" color="body">
                        {`${swapDetail.destination_amount || '-'} ${
                          swapDetail.destination_symbol
                        }`}
                      </Text>
                    </Box>
                  )}
                  {trType === 'TRANSFER' && (
                    <Box
                      w="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexDir={{ base: 'row', lg: 'column' }}
                    >
                      <Text color="body2">{transferDetail.text}</Text>
                      <Text fontSize="xs" color="body">
                        {transferDetail.user || '-'}
                      </Text>
                    </Box>
                  )}
                  {trType !== 'SWAP' && !isBrokerTr && (
                    <Box
                      w="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexDir={{ base: 'row', lg: 'column' }}
                    >
                      <Text color="body2">{t('trFee')}</Text>
                      <Text fontSize="xs" color="body">
                        {transaction.fee.fee_amount
                          ? transaction.fee.fee_amount + amountTr.symbol
                          : '0'}
                      </Text>
                    </Box>
                  )}
                  <Box
                    w="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDir={{ base: 'row', lg: 'column' }}
                  >
                    <Text color="body2">{t('dateTime')}</Text>
                    <Text fontSize="xs" color="body">
                      {dayjs(transaction.created_at).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )}
                    </Text>
                  </Box>
                  <Box
                    minH="40px"
                    maxH="40px"
                    minW="40px"
                    maxW="40px"
                    display={{ base: 'none', lg: 'flex' }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <TransactionStatus status={transaction.status} />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </VStack>
      )}
      {profile && selectedTransaction && (
        <TransactionDetailModal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setSelectedTransaction(null);
          }}
          transaction={selectedTransaction}
          profile={profile}
        />
      )}
    </VStack>
  );
};
export default RecentTransactions;
