import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Stack, Text } from '@chakra-ui/react';
import { ReactComponent as SuccessfulIcon } from 'assets/icons/successIcon.svg';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import { Button } from 'components';

const Successful: React.FC<{
  amount: number;
  counter: number;
  symbol: string;
  walletTitle: string;
}> = ({ amount, counter, symbol, walletTitle }) => {
  const { t } = useTranslation('component.payment');

  const navigate = useNavigate();

  return (
    <Stack
      bgColor="dark"
      px={4}
      py={2}
      w={{ base: 'full', lg: '600px' }}
      align="center"
    >
      <SuccessfulIcon width={60} />
      <Text
        fontSize={{ base: 'xl', md: 'xx-large' }}
        fontWeight="500"
        color="green"
      >
        {t('successTitle')}
      </Text>
      <Alert status="success" my={10} justifyContent="center">
        <Text fontWeight="500" color="green">
          {t('successDescription')}
        </Text>
      </Alert>
      <Stack w="100%" flexDir="row" justify="space-between">
        <Text fontSize="xl">{t('wallet')}</Text>
        <Text fontWeight="bold" fontSize="xl">
          {walletTitle}
        </Text>
      </Stack>
      <Stack w="100%" flexDir="row" justify="space-between">
        <Text fontSize="xl">{t('amount')}</Text>
        <Text fontWeight="bold" fontSize="xl">
          {amount + ' ' + symbol}
        </Text>
      </Stack>
      <Stack w="100%" align="center" justify="center" flexDir="column">
        <Button
          size="sm"
          minW={40}
          title={t('redirectToDashboard') + ' ' + counter.toString()}
          onClick={() => navigate(routes.dashboard.url)}
        />
      </Stack>
    </Stack>
  );
};

export default Successful;
