import React from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Box, Stack, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDepositPayment, useFee, usePaymentGateways, useRate } from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';
import DestinationAmount from 'pages/Withdraw/components/DestinationAmount';
import { ListResponse, TransactionMethod, Wallet } from 'types';
import * as yup from 'yup';

import { BankTransferHint, Button, Input, Spinner } from 'components';

type FormResultType = {
  gateway_id: string;
  amount: number;
};

type PaymentTabProps = {
  wallet?: Wallet;
  trMethods: ListResponse<TransactionMethod> | undefined;
  isSuccessTrMethods: boolean;
  isFetchingTrMethods: boolean;
  isLoadingTrMethods: boolean;
};

const PaymentTab: React.FC<PaymentTabProps> = ({
  wallet,
  trMethods,
  isSuccessTrMethods,
  isFetchingTrMethods,
  isLoadingTrMethods,
}) => {
  const { t } = useTranslation('component.deposit.paymentGateway');
  const [searchparams, setSearchParams] = useSearchParams();

  // Manage form in 4 code block
  const schema = yup.object().shape({
    transaction_method: yup
      .string()
      .label(t('form.transaction_method'))
      .required(),
    gateway_id: yup.string().label(t('form.transaction_method')).required(),
    amount: yup.number().label(t('form.amount')).required(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const formProps = useInputAttributes(register, errors, {
    transaction_method: t('form.transaction_method'),
    gateway_id: t('form.gateway_id'),
    amount: t('form.amount'),
  });

  const trMethod =
    trMethods?.list.find((item) => item.id === watch('transaction_method')) ||
    trMethods?.list[0] ||
    null;

  React.useEffect(() => {
    if (isSuccessTrMethods && transactionMethodList.length > 0) {
      setValue('transaction_method', transactionMethodList[0].value);
    }
    setSearchParams(searchparams);
  }, [isSuccessTrMethods, isFetchingTrMethods]);

  const transactionMethodList =
    trMethods?.list?.map((tr) => ({
      label: tr.title,
      value: tr.id,
      key: tr.key,
    })) || [];

  const { data: paymentGateways } = usePaymentGateways({
    queries: {
      pay_currency: trMethod?.pay_currency.id || '',
    },
    enabled: Boolean(trMethod?.id) && Boolean(wallet),
  });

  const paymentGatewayOptions =
    paymentGateways?.map((payment) => ({
      label: payment.title,
      value: payment.id,
    })) || [];

  const {
    data: fee,
    isLoading: isLoadingFee,
    isFetching: isFetchingFee,
  } = useFee({
    queries: {
      currency: wallet?.currency.id || '',
      transaction_method: trMethod?.id || '',
    },
    enabled: Boolean(trMethod?.id) && Boolean(wallet),
  });

  const {
    data: rate,
    mutateAsync: getRate,
    isLoading: rateLoading,
  } = useRate();

  React.useEffect(() => {
    if (trMethod) {
      getRate({
        destination_symbol: trMethod.pay_currency.symbol || '',
        source_symbol: wallet?.currency.symbol || '',
        transaction_method_key: trMethod.key,
      });
    }
  }, [trMethod]);

  const { mutateAsync: submit, isLoading: submitLoading } = useDepositPayment();

  const onSubmit = async (data: FormResultType) => {
    submit({
      ...data,
      destination_wallet: wallet?.id || '',
      pay_amount: Math.ceil(data.amount * (rate?.rate || 0)),
    }).then((res) => {
      if (res.redirect_url) {
        reset();
        window.location.href = res.redirect_url;
      }
    });
  };

  return (
    <Box bgColor="dark" my={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDir={{ base: 'column', md: 'row' }}
          w="100%"
          p={8}
          gap={25}
        >
          <Box w={{ base: '100%', md: '50%' }}>
            <Input
              {...formProps.transaction_method}
              isSelectBox
              options={transactionMethodList}
              isDisabled={transactionMethodList.length < 1}
              mb={8}
            />
            <Input
              {...formProps.gateway_id}
              isSelectBox
              options={paymentGatewayOptions}
              isDisabled={paymentGatewayOptions.length < 1}
            />
          </Box>
        </Box>
        {isLoadingTrMethods && (
          <Stack w="100%" align="center" justify="center">
            <Spinner size="md" />
          </Stack>
        )}
        {trMethod?.fee && (
          <Box px={8} py={4} display="flex">
            <Text color="body2">{t('fee')}</Text>
            {trMethod?.fee && (
              <Text pl={4}>{trMethod?.fee ? '% ' + trMethod?.fee : '0'}</Text>
            )}
          </Box>
        )}
        {isLoadingFee && isFetchingFee && (
          <Stack w="100%" align="center" justify="center" py={10}>
            <Spinner size="md" />
          </Stack>
        )}
        {fee && wallet && (
          <Stack w="100%" mb={10}>
            <BankTransferHint
              maximum_credit={fee.maximum_credit}
              minimum_credit={fee.minimum_credit}
              maximum={fee.maximum}
              minimum={fee.minimum}
              percent={fee.percent}
              symbol={wallet.currency.symbol}
              type="deposit-payment"
            />
          </Stack>
        )}
        {trMethod?.metadata?.note && (
          <Box bgColor="lightPrimary" p={4} textAlign="center" w="100%">
            {trMethod?.metadata?.note
              .split('\n')
              .map((item: string, index: number) => {
                return (
                  <Text w="100%" textAlign="left" color="warning" key={index}>
                    {item}
                  </Text>
                );
              })}
          </Box>
        )}
        <Stack
          flexDir={{ base: 'column', xl: 'row' }}
          align="flex-start"
          w="100%"
          px={8}
        >
          <Box w={{ base: '100%', xl: '50%' }} minW="300px">
            <Input
              {...formProps.amount}
              bgColor="lightPrimary"
              border="none"
              borderRadius={5}
              py={10}
              px={10}
              type="number"
              step="0.01"
              min="0"
              fontSize="2xl"
              fontWeight="500"
              _placeholder={{ color: 'grey' }}
            />
            <Box w={{ base: '100%', xl: '50%' }} minW="300px">
              {parseFloat((watch('amount') || 0).toString()) > 0 &&
                wallet &&
                rate?.rate && (
                  <DestinationAmount
                    amount={watch('amount')}
                    type="rate"
                    symbol={trMethod?.pay_currency.symbol || ''}
                    rate={rate?.rate || 0}
                    source_symbol={wallet.currency.symbol || ''}
                    loading={rateLoading}
                    isInsufficient={false}
                    ceil={true}
                  />
                )}
            </Box>
          </Box>
        </Stack>
        <VStack w={{ base: '100%', lg: '50%' }} align="flex-start" p={8}>
          <Button
            type="submit"
            variant="filled"
            display="block"
            title={t('submit')}
            minW="100%"
            isDisabled={rateLoading}
            isLoading={submitLoading}
            mt={{ base: 8, md: 8 }}
          />
        </VStack>
      </form>
    </Box>
  );
};

export default PaymentTab;
