import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTimer, useTransaction } from 'hooks';
import { routes } from 'statics';

import Failed from './Failed';
import Successful from './Successful';

const PaymentProcess: React.FC<{ transactionID: string }> = ({
  transactionID,
}: {
  transactionID: string;
}) => {
  const { data } = useTransaction({ id: transactionID });
  const navigate = useNavigate();

  const { counter } = useTimer(10);

  React.useEffect(() => {
    if (counter === 0) {
      navigate(routes.dashboard.url);
    }
  }, [counter]);

  if (data?.status === 'SETTLED') {
    return (
      <Successful
        counter={counter}
        amount={data?.destination_amount || 0}
        symbol={data?.destination_wallet.currency.symbol || ''}
        walletTitle={data?.destination_wallet.currency.title || ''}
      />
    );
  }

  if (data?.status === 'FAILED') {
    return (
      <Failed
        counter={counter}
        amount={data?.destination_amount || 0}
        symbol={data?.destination_wallet.currency.symbol || ''}
        walletTitle={data?.destination_wallet.currency.title || ''}
      />
    );
  }
};

export default PaymentProcess;
