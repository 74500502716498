import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { DepositPaymentType, ErrorResponse } from 'types';

import { api } from 'utils';

const useDepositPayment = (): UseMutationResult<
  { id: string; redirect_url: string },
  ErrorResponse,
  DepositPaymentType
> => {
  return useMutation((data) => api.post(url.depositPayment, data));
};

export default useDepositPayment;
