import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse, Transaction } from 'types';

const useTransaction = ({
  id,
}: {
  id: string;
}): UseQueryResult<Transaction, ErrorResponse> => {
  return useQuery([`${url.transactions}/${id}`]);
};

export default useTransaction;
