import { Box, HStack, Stack, StackProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'localization';

import { Spinner } from 'components';
import { fixDecimal } from 'utils';

type DestinationAmountProps = {
  fee?: number;
  rate?: number;
  amount: number;
  symbol: string;
  source_symbol?: string;
  loading: boolean;
  isCrypto?: boolean;
  isInsufficient?: boolean;
  type?: 'fee' | 'rate';
  ceil?: boolean;
} & StackProps;

const DestinationAmount: React.FC<DestinationAmountProps> = ({
  fee = 0,
  rate = 0,
  amount = 0,
  symbol,
  source_symbol,
  loading,
  isInsufficient = false,
  isCrypto = false,
  type = 'fee',
  ceil = false,
  ...rest
}: DestinationAmountProps) => {
  const { t } = useTranslation('component.withdraw');

  return (
    <Stack {...rest}>
      <Box>
        {type === 'fee' && (
          <Text color="body2">{t('fee') + ` ${fee} ${symbol}`}</Text>
        )}
        {type === 'rate' && (
          <Text color="body2">
            {t('rate') +
              ` ${
                source_symbol ? `1 ${source_symbol}` : ''
              } = ${fixDecimal({ value: rate, fractionDigits: 5 })} ${symbol}`}
          </Text>
        )}
      </Box>
      <Box w="100%" pos="relative">
        <HStack
          bgColor="lightPrimary"
          border="none"
          borderRadius={5}
          py={5}
          px={10}
          minH="80px"
        >
          <Text fontSize="2xl" fontWeight="500">
            {isInsufficient ? (
              t('insufficientBalance')
            ) : loading ? (
              <Spinner size="md" />
            ) : ceil ? (
              type === 'fee' ? (
                amount - fee
              ) : (
                Math.ceil(amount * rate)
              )
            ) : (
              fixDecimal({
                value: type === 'fee' ? amount - fee : amount * rate,
                fractionDigits: isCrypto ? 5 : 2,
              })
            )}
          </Text>
          {symbol && (
            <Text fontSize="2xl" fontWeight="500">
              {symbol}
            </Text>
          )}
        </HStack>
        <Text pos="absolute" top={0} left={2} fontSize="xs" color="body2">
          {t('paymentAmount')}
        </Text>
      </Box>
    </Stack>
  );
};

export default DestinationAmount;
