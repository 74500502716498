import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Stack, Text } from '@chakra-ui/react';
import { ReactComponent as Failed } from 'assets/icons/errorIcon.svg';
import { useTimer } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import { Button } from 'components';

const Invalid: React.FC = () => {
  const { t } = useTranslation('component.payment');

  const navigate = useNavigate();
  const { counter } = useTimer(10);

  React.useEffect(() => {
    if (counter === 0) {
      navigate(routes.dashboard.url);
    }
  }, [counter]);

  return (
    <Stack
      bgColor="dark"
      px={4}
      py={2}
      w={{ base: 'full', lg: '600px' }}
      align="center"
    >
      <Failed width={60} />
      <Text
        fontSize={{ base: 'xl', md: 'xx-large' }}
        fontWeight="500"
        color="error"
      >
        {t('invalidTitle')}
      </Text>
      <Alert status="error" my={10} justifyContent="center">
        <Text fontWeight="500" color="error">
          {t('invalidDescription')}
        </Text>
      </Alert>
      <Stack w="100%" align="center" justify="center" flexDir="column">
        <Button
          size="sm"
          minW={40}
          title={t('redirectToDashboard') + ' ' + counter.toString()}
          onClick={() => navigate(routes.dashboard.url)}
        />
      </Stack>
    </Stack>
  );
};

export default Invalid;
